import React from "react";
import style from "./about.module.less";
import Img from "gatsby-image";

const AboutTile = props => {
  const { img, textH4, textH3, alt, height, width } = props;
  return (
    <div className={style.aboutTile}>
      <div className={style.aboutBlock}>
        <Img
          fixed={img}
          height={height || 64}
          width={width || 64}
          alt={alt || "icon LikeObmen Social Booster"}
        />
      </div>
      <div className={`textCenter ${style.mrTp26PX}`}>
        <h4>{textH4 || ""}</h4>
        <h3>{textH3 || ""}</h3>
      </div>
    </div>
  );
};

export default AboutTile;
