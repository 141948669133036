import React, { useState } from "react";
import { Row, Col, Modal } from "antd";
import AboutTile from "../../AbouTile";
import SEO from "../../seo";
import { useTranslation } from "react-i18next";
import Img from "gatsby-image";
import getYouTubeId from "get-youtube-id";
import YouTube from "react-youtube";

const BlockContent = require("@sanity/block-content-to-react");
const opts = {
  height: "250px",
  width: "100%"
};
const serializers = {
  types: {
    youtube: ({ node }) => {
      const { url } = node;
      const id = getYouTubeId(url);
      return (
        <Row type="flex" justify="center">
          <Col xs={24} sm={24} md={24} lg={8} xl={12}>
            <YouTube videoId={id} opts={opts} />
          </Col>
        </Row>
      );
    }
  }
};

const AboutMe = props => {
  const keywords = props.PageData.ceo_tags.map(item => item.translate);

  const [visibleModal, toogleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalDesc, setModalDesc] = useState("");
  const [modalImg, setModalImg] = useState("");

  const { i18n, t } = useTranslation("common");

  const showModal = item => {
    setModalTitle(item.title.translate);
    setModalDesc(item.description.translate);
    setModalImg(item.icon.asset.fixed);

    toogleModal(true);
  };
  const hideModal = () => {
    toogleModal(false);
  };
  const goToApp = () => {
    toogleModal(false);
    window.location.replace("https://likeobmen.ru/app/#/signup?p=free50");
  };

  const itemsSocialBoxShow = props.SocialBoxItems.map(item => (
    <Col xs={24} sm={24} md={12} lg={8}>
      <span style={{ cursor: "pointer" }} onClick={() => showModal(item)}>
        <AboutTile
          img={item.icon.asset.fixed}
          height={60}
          alt={item.title.translate}
          textH4={item.title.translate}
          textH3={item.name.translate}
        />
      </span>
    </Col>
  ));

  return (
    <>
      <div>
        <SEO
          lang={i18n.language}
          title={props.PageData.ceo_title.translate}
          description={props.PageData.ceo_description.translate}
          path={""}
          keywords={keywords}
        />
        <h1 className="titleSeparate">{props.PageData.title.translate}</h1>
      </div>

      <div>
        <Row gutter={[20, 20]}>{itemsSocialBoxShow}</Row>

        <BlockContent
          blocks={props.PageData.body.translate}
          serializers={serializers}
        />

        <Modal
          centered
          title={modalTitle}
          visible={visibleModal}
          onOk={goToApp}
          onCancel={hideModal}
          cancelText={t("cancel")}
          okText={t("modalBtn")}
        >
          <div className={`textCenter`}>
            <Img fixed={modalImg} alt={modalTitle} />
            <p>{modalDesc}</p>
          </div>
        </Modal>
      </div>
    </>
  );
};
export default AboutMe;
