import React from "react";
import { graphql } from "gatsby";
import { Layout } from "antd";

import Header from "../components/PageLayout/Header";

import SidebarWrapper from "../components/PageLayout/Sidebar";
import AboutMe from "../components/PageFragments/HomePage/AboutMe";

const IndexPage = ({ data }) => {
  const MenuItems = data.allSanityMenu.nodes;
  const SocialBoxItems = data.allSanitySocialBox.nodes;
  const PageData = data.sanityPage;

  return (
    <Layout className="outerPadding">
      <Layout className="container">
        <Header MenuItems={MenuItems} />
        <SidebarWrapper>
          <>
            <AboutMe PageData={PageData} SocialBoxItems={SocialBoxItems} />
          </>
        </SidebarWrapper>
      </Layout>
    </Layout>
  );
};

export const query = graphql`
  query Index($language: String) {
    allSanityMenu(filter: { disabled: { in: true } }, sort: { fields: indx }) {
      nodes {
        title {
          translate(language: $language)
        }
        path
        externUrl
      }
    }
    allSanitySocialBox(sort: { fields: indx, order: ASC }) {
      nodes {
        name {
          translate(language: $language)
        }
        title {
          translate(language: $language)
        }
        description {
          translate(language: $language)
        }
        title {
          translate(language: $language)
        }
        icon {
          asset {
            fixed(width: 60) {
              ...GatsbySanityImageFixed
            }
          }
        }
      }
    }
    sanityPage(slug: { current: { eq: "/home" } }) {
      title {
        translate(language: $language)
      }
      body {
        translate(language: $language)
      }
      ceo_description {
        translate(language: $language)
      }
      ceo_tags {
        translate(language: $language)
      }
      ceo_title {
        translate(language: $language)
      }
      slug {
        current
      }
    }
  }
`;

export default IndexPage;
